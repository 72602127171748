import React from "react";

interface MainContentProps {
  children: React.ReactNode;
  isMin?: boolean;
}

const MainContent = (props: MainContentProps) => {
  return (
    <div
      style={{
        maxWidth: "1232px",
        width: "100%",
        minHeight: props.isMin ? "82.5vh" : "63.15vh",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      {props.children}
    </div>
  );
};

export default MainContent;
