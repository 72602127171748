import React from "react";
import { ThemeProvider } from "styled-components";
import Ws10Theme from "@vfuk/core-theme-ws10";
import { Helmet } from "react-helmet";
import "../custom.css";

const Layout = ({ children, title }) => {
  Ws10Theme.setBaseAssetLocation("/assets/ws10/");

  return (
    <ThemeProvider theme={Ws10Theme}>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {/* Global styles applies fonts and default styling */}
      <Ws10Theme.globalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Layout;
